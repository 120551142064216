@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .root-style {
    @apply sm:ml-64 mt-44 md:mt-28;
  }

  .input-style {
    @apply mt-1 p-2 w-full border rounded-md focus:border-gray-400 ring-1 ring-gray-300;
  }

  .li-style {
    @apply hover:bg-bgclr8 hover:text-bgclr3 hover:border-b hover:border-gray-600 py-2 px-5;
  }

  .active {
    @apply bg-bgclr8 text-bgclr3 border-b border-gray-600;
  }

  .bankNavbarStyle {
    @apply bg-bgclr text-txtclr;
  }

  .searchBar-style {
    @apply flex justify-center text-[16px] w-full my-6 mr-3 gap-4 text-bgclr;
  }

  .searchBar-input {
    @apply border border-[#EBEBEB] rounded-md w-full pl-6 pr-5 py-3;
  }

  .searchBar-btn {
    @apply bg-btnclr rounded-md px-6 font-[500] text-txtclr;
  }
}

.stdropdown-input {
  display: flex;
  border: 1.5px solid #0B1E59;
  border-radius: 5px;
  padding: 0.3em;
}

.stdropdown-input input {
  border-radius: 5px;
}

.stdropdown-tool {
  /* @media (min-width: 1024px) {
    margin-left: 1.5em;
  }
  @media (min-width: 340px) {
    margin-left: 8.5em;
  }
  padding: 0.5em; */
  display: none;
}

.stdropdown-menu {
  position: relative;

  @media (min-width: 768px) {
    position: absolute;
  }

  max-height: 250px;
  overflow-y: auto;
  width: 240px;
  border: 1px solid #0B1E59;
  padding: 0.5em;
  margin-left: 8px;
  background-color: white;
}

div.popup-overlay {
  position: absolute;
}

.react-calendar {
  border: none !important;
}

.swal2-actions .swal2-confirm {
  background-color: #1D4ED8;
}

.tox-statusbar__branding {
  display: none;
}

#swal2-title {
  font-size: 16px;
}

.swal2-error {
  width: 48px;
  height: 48px;
  margin-top: 20px;
}

.swal2-x-mark {
  font-size: 10px;
}

.swal2-popup {
  width: 430px;
  line-height: 5px;
  border-radius: 10px;
}

#swal2-title {
  font-size: 20px;
}

#swal2-html-container {
  font-size: 16px;
  margin-top: 20px;
}

.swal2-actions .swal2-confirm {
  width: 380px;
  background-color: #0B1E59 !important;
  color: white !important;
  font-weight: 500;
  font-size: 16px;
  margin-top: 10px;
  height: 40px;
  border: none !important;
  box-shadow: none !important;
  border-radius: 8px;
  /* margin-bottom: -5px; */
}

.swal2-actions .swal2-cancel{
  height: 40px;
  font-weight: 500;
  font-size: 16px;
}

#a4-container {
  width: 794px; /* Approx A4 width */
  height: 1123px; /* Approx A4 height */
  margin: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  overflow: hidden;
  border: 1px solid #ccc; /* Optional for A4 look */
}

@media screen and (max-width: 820px) {
  #a4-container {
      transform: scale(0.9); /* Adjust scale for smaller screens */
      transform-origin: top center;
  }
}

@media screen and (max-width: 600px) {
  #a4-container {
      transform: scale(0.7); /* Further scale down */
      transform-origin: top center;
  }
}

@media print {
  #a4-container {
      width: 210mm;
      height: 297mm;
      margin: 0;
      box-shadow: none;
      border: none;
  }
}

.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
  color: black;
}

.pdf-content {
  line-height: 1.2; /* Adjust as needed */
  font-family: Arial, sans-serif;
  font-size: 12px;
  margin: 0;
  padding: 10px; /* Add padding to avoid clipping */
}